
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



























































































































































































































































































































































































































































































































$tv-news-height: 17rem;
$tv-news-height-l: 47rem;

.tv-news,
[class*='tv-news--'] {
  --tv-news-item-width: 32rem;

  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: $spacing * 6 0 $spacing;
  color: $c-white-dark;

  @include mq(l) {
    --tv-news-item-width: 50vw;

    position: relative;

    .tv-universe-sport & {
      padding: $spacing * 2 0 $spacing * 5;
    }
  }

  @include mq(xxl) {
    --tv-news-item-width: 80rem;
  }
}

.tv-news__slidy-container {
  position: relative;
  display: none;
  width: 100%;

  .before-enter & {
    opacity: 0;
  }

  @include mq(l) {
    display: block;
  }
}

.tv-news__slidy-outer {
  pointer-events: none;
}

.tv-news__item {
  position: relative;
  width: var(--tv-news-item-width);
  height: 100%;

  & + & {
    margin-left: $spacing;
  }

  @include mq(l) {
    width: 100%;
    height: fit-content;

    &.is-active {
      opacity: 1;
    }

    & + & {
      margin-left: $spacing * 2.5;
    }
  }

  @include mq(xxl) {
    & + & {
      margin-left: $spacing * 3.4;
    }
  }
}

.tv-news__slidy {
  display: flex;
  overflow: auto;
  width: 100%;
  margin: 0;
  color: var(--foreground-color);
  list-style-type: none;

  .tv-news__item {
    flex-shrink: 0;
  }

  @include mq(l) {
    overflow: visible;
    padding: 0;
  }
}

.tv-news__popup-trigger {
  @extend %button-nostyle;
  @include get-all-space;

  z-index: 2;

  @include mq(l) {
    width: var(--tv-news-item-width);
    height: 100%;
  }
}

.tv-news__card {
  width: 100%;
  height: 100%;

  button:hover + &,
  button:focus-visible + & {
    ::v-deep img {
      transform: scale(1.05);
    }
  }

  ::v-deep .card__thumbnail-container {
    height: 11.6rem;
  }

  @include mq(l) {
    flex-direction: row;
    align-items: center;
    overflow: visible;
    height: fit-content;
    background: none;
    border-radius: 0;
    box-shadow: none;

    &.card:hover {
      ::v-deep img {
        transform: none;
      }
    }

    ::v-deep {
      .card__thumbnail-container {
        position: relative;
        z-index: 1;
        flex-grow: 0;
        width: var(--tv-news-item-width);
        height: fit-content;
        aspect-ratio: 16/9;
        border-radius: 1rem;
      }

      .card__body {
        flex: 1;
        padding-left: $spacing * 4;
        line-height: 3rem;
      }

      .card__title {
        font-size: 2.4rem;
      }

      .card__subtitle {
        font-size: 1.8rem;
      }

      .card__label {
        z-index: 2;
        top: initial;
        bottom: 0;
        left: calc(var(--tv-news-item-width) * 0.5);
        transform: translate(-50%, 50%);
      }
    }
  }
}

.tv-news__background {
  @include get-all-space;

  z-index: -1;

  img {
    @include image-fit;

    height: 105%;
    opacity: 0;
    transition: 2s 1s opacity $ease-out-expo;

    &.is-active {
      opacity: 0.3;
    }
  }

  .before-enter & {
    transform: scale(1.1);
  }

  .tv-universe-sport & {
    mask-image: linear-gradient(
      transparent 0%,
      $black 50%,
      $black 70%,
      transparent 100%
    );
  }

  @include mq($until: l) {
    display: none;

    .tv-universe-sport & {
      bottom: initial;
      display: block;
      height: 100vh;

      img {
        filter: blur(30px);
      }
    }
  }
}

::v-deep {
  .tv-news__slidy-controls {
    @include mq($until: l) {
      display: none;
    }
  }

  .tv-news__slidy-nav {
    margin-bottom: 0;

    @include mq(l) {
      justify-content: flex-end;
      margin: 0 0 0 $spacing * 6;
    }
  }

  .tv-news__slidy-controls,
  .tv-news__slidy-nav {
    @include mq(l) {
      @include center-y;

      z-index: 2;
      // top: 27rem;
      left: calc(var(--tv-news-item-width) + 8rem);
      margin-top: $spacing * 5;
    }
  }
}

.tv-news__carousel {
  ::v-deep {
    .carousel__scroller {
      --padding-block: 2rem;

      margin-block: calc(var(--padding-block) * -1);
      padding-block: var(--padding-block);
    }
  }

  @include mq($from: l) {
    display: none;
  }
}
