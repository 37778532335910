
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































































































.discover-universes {
  // prettier-ignore
  @include fluid(padding-top, (s: 60px, l: 150px));
  // prettier-ignore
  @include fluid(padding-bottom, (s: 100px, l: 150px));

  overflow: hidden; // "divertissement" creates an horizontal scrollbar on mobile
  position: relative;
  z-index: 1;
  padding-bottom: 10rem;
  color: $white;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 10%;
    right: 10%;
    width: 66%;
    height: 33%;
    background: $c-pink-medium;
    border-radius: 50%;
    filter: blur(204px);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
      201.44% 95.26% at 50% 70.02%,
      rgba(47, 57, 110, 0.7) 0%,
      rgba(33, 45, 84, 0.445) 36.48%,
      rgba(8, 23, 40, 0) 100%
    );
    filter: blur(74px);
  }
}

.discover-universes__inner {
  display: flex;
  flex-direction: column;

  @include mq(l) {
    flex-direction: row;
    align-items: center;
  }
}

.title {
  margin-bottom: 3rem;
  text-align: center;

  @include mq(l) {
    width: 50%;
    margin-bottom: 0;
    text-align: left;
  }
}

.universe {
  // prettier-ignore
  @include fluid(width, (s: 100px, l: 140px));

  position: relative;
  color: currentColor;
  text-decoration: none;

  &:not(:last-child) {
    // prettier-ignore
    @include fluid(margin-right, (s: 10px, l: 20px));
  }

  &:hover {
    .universe__visual {
      transform: scale(1.1);
    }

    .universe__title {
      transform: translateY(0);
    }
  }
}

.universe__visual-container {
  @include aspect-ratio(100, 173);

  overflow: hidden;
  width: 100%;
  border-radius: 2rem;
  will-change: transform;
  transform: rotate(9deg);
  box-shadow: $box-shadow-lvl-1;

  &::before {
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(8, 23, 40, 0) 71.58%,
      rgba(8, 23, 40, 0.8) 100%
    );
  }
}

.universe__visual {
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform $ease-out-cubic 500ms;
  will-change: transform;
}

.universes-container {
  display: flex;
  justify-content: center;

  @include mq(l) {
    width: 50%;
  }
}

.universe__title {
  position: absolute;
  top: 100%;
  width: 100%;
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 1.36;
  text-align: center;
  transform: translateY(-50%);
  transition: transform $ease-out-cubic 500ms;
}
