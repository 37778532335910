
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































.tv-price,
[class*='tv-price--'] {
  color: $c-white-dark;
  text-align: left;

  &[class*='--centered'] {
    @extend %text-center;
  }
}

.tv-price__title {
  .tv-price--centered & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tv-price__title__old {
  @extend %fw-light;

  display: block;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-decoration: line-through;

  .tv-price--centered & {
    margin-right: $spacing * 0.4;
  }
}
