
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































































































































$c-tv-light-gray: #f8f8f8;

.tv-offers {
  position: relative;
  z-index: 1;
  font-family: $ff-alt;
  background: $white;
}

.tv-offers-inner {
  color: $c-promo-card;

  @include mq($until: l) {
    padding: $spacing * 2 0 0;
  }

  @include mq(l) {
    display: flex;
    padding-top: $spacing * 8;
    padding-bottom: $spacing * 8;
  }
}

.tv-offers__title {
  @extend %fw-light;
  @extend %text-center;

  max-width: 10em;
  margin: 0 auto $spacing;

  ::v-deep strong {
    font-weight: 600 !important;
  }

  @include mq(l) {
    width: col(4);
    min-width: 36rem;
    max-width: none;
    margin: 0 col(0.5) 0 0;
    text-align: left;
  }
}

.tv-offers__list {
  @extend %list-nostyle;

  @include mq(l) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 $spacing * 2;
    margin-top: $spacing * -4;
  }
}

.tv-offers__item-outer {
  padding-top: $spacing * 1.2;
  padding-bottom: $spacing * 1.3;

  &:nth-child(2n) {
    background: $c-tv-light-gray;
  }

  @include mq(l) {
    margin: 0;
    padding: 0;

    &:nth-child(even) {
      margin-top: $spacing * 6;

      // &:nth-last-child(3) {
      // grid-row: 1/3;
      // }
    }

    &:nth-child(odd) {
      background: none;
    }
  }
}

.tv-offers__item {
  @extend %text-center;

  overflow: hidden;
  background: $white;
  border-radius: 1.5rem;
  box-shadow: $box-shadow-lvl-0;
}

.tv-offers__item__title {
  @extend %fw-normal;

  margin: $spacing * 0.6 $spacing $spacing * 0.1;

  ::v-deep strong {
    @extend %fw-semi;
  }

  @include mq(l) {
    margin: $spacing * 1.2 $spacing * 1.5 $spacing * 0.25;
  }
}

.tv-offers__item__subtitle {
  margin: 0 $spacing $spacing;
}

.tv-offers__item__picture {
  position: relative;
  width: 14rem;
  height: 5rem;
  margin: 0 auto;

  img {
    @include image-fit(contain);
  }
}

.tv-offers__item__price {
  position: relative;
  margin-top: $spacing * 1.3;
  padding: $spacing $spacing $spacing * 0.6;
  background: $c-bunting;

  @include mq(l) {
    padding: $spacing * 2 $spacing;
  }
}

.tv-offers__item__price__label {
  @include center-xy;

  top: 0;
}

.tv-offers__item__link {
  display: flex;
  width: fit-content;
  margin: $spacing auto 0;

  ::v-deep .btn__icon {
    margin-left: $spacing * 0.5;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
